import { Text } from '@cinch-labs/design-system'
import cx from 'classnames'
import React from 'react'

import skeletonStyles from '../skeleton.module.css'
import styles from './price.module.css'

export const numberWithCommas = (x: string | number) => {
  if (typeof x === 'string') {
    return Number.parseInt(x.replace(/,/g, ''), 10).toLocaleString()
  }

  return Math.round(x).toLocaleString()
}

const penceToPounds = (n: number) => Math.round(n / 100)

export interface PriceProps {
  price: number
  vehicleId: string
  shouldShowCarsOnOfferTag?: boolean
  discountInPence?: number

  isPending?: boolean
  showSmallFontSize?: boolean
}

const Price: React.FC<PriceProps> = ({
  price,
  vehicleId,
  shouldShowCarsOnOfferTag = false,
  discountInPence,

  isPending,
  showSmallFontSize = false,
}) => {
  const fullPrice = `£${numberWithCommas(price)}`

  const wasPriceId = `${vehicleId}-was-price`

  const renderCashPrice = () => (
    <Text
      element="span"
      fontSizeFixed="l"
      fontWeight="semibold"
      aria-label={`Cash price. ${fullPrice}.`}
      aria-describedby={discountInPence ? wasPriceId : undefined}
      className={cx(styles.cashPrice, {
        [skeletonStyles.base]: isPending,
        [skeletonStyles.active]: isPending,
      })}
    >
      {fullPrice}
    </Text>
  )

  const renderCashPriceAndWasPrice = () => {
    const discount = penceToPounds(discountInPence ?? 0)
    const wasPrice = `£${numberWithCommas(price + discount)}`

    return (
      <div className={styles.cashPrice}>
        <Text
          element="span"
          id={wasPriceId}
          data-testid="was-price"
          fontSizeFixed="xs"
          fontWeight="medium"
          aria-hidden={true}
          className={cx(styles.wasPrice, {
            [skeletonStyles.base]: isPending,
            [skeletonStyles.active]: isPending,
          })}
        >
          Was <s>{wasPrice}</s>
        </Text>

        {renderCashPrice()}
      </div>
    )
  }

  return (
    <div
      className={cx(styles.priceContainer, {
        [styles.smallFontSize]: showSmallFontSize,
      })}
    >
      {shouldShowCarsOnOfferTag
        ? renderCashPriceAndWasPrice()
        : renderCashPrice()}
    </div>
  )
}

export default Price
