import { Text, TextFontSizes, TextStyles } from '@cinch-labs/design-system'
import cx from 'classnames'

import { LOADING_TEXT } from '../../constants'
import skeletonStyles from '../skeleton.module.css'

interface Props {
  isPending?: boolean
  type?: TextStyles | keyof typeof TextStyles
  className?: string
  element?: keyof JSX.IntrinsicElements
  fontSizeFixed?: keyof typeof TextFontSizes // TODO: Remove once Card Cards AB Test has concluded
  children?: React.ReactNode
}

export const Title: React.FC<Props> = ({
  children,
  isPending = false,
  type,
  className = '',
  element = 'h5',
  ...props
}) => (
  <Text
    element={element}
    className={cx(
      skeletonStyles.base,
      { [skeletonStyles.active]: isPending },
      className,
    )}
    type={type || TextStyles.mainTitle}
    {...props}
  >
    {isPending ? LOADING_TEXT : children}
  </Text>
)

export default Title
