import { Text, TextFontSizes } from '@cinch-labs/design-system'
import cx from 'classnames'

import { LOADING_TEXT } from '../../constants'
import skeletonStyles from '../skeleton.module.css'
import styles from './subtitle.module.css'

interface SubtitleProps {
  isPending?: boolean
  className?: string
  fontSize?: keyof typeof TextFontSizes // TODO: Remove once Card Cards AB Test has concluded
  children?: React.ReactNode
}

export const Subtitle: React.FC<SubtitleProps> = ({
  children,
  isPending = false,
  className = '',
  fontSize,
}) => (
  <Text
    fontSizeFixed={fontSize || 'xs'}
    className={cx(className, styles.subtitle, {
      [skeletonStyles.base]: isPending,
      [skeletonStyles.active]: isPending,
    })}
  >
    {isPending ? LOADING_TEXT : children}
  </Text>
)

export default Subtitle
