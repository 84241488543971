/* eslint-disable react/jsx-no-useless-fragment */
import { Drawer } from '@cinch-labs/design-system'
import styles from './admin-fee-drawer.module.css'

export interface AdminFeeDrawerProps {
  showAdminFeeDrawer: boolean
  onClose: () => void
}
export const AdminFeeDrawer: React.FC<AdminFeeDrawerProps> = ({
  showAdminFeeDrawer,
  onClose,
}: AdminFeeDrawerProps) => (
  <>
    {showAdminFeeDrawer && (
      <Drawer
        onClose={onClose}
        header={'Admin Fee'}
        ariaLabelledById="admin-fee-drawer"
      >
        <div>
          <p className={styles.paragraph}>
            The £99 admin fee powers your faff-free customer experience,
            including administration costs associated with processing your car.
          </p>
          <p className={styles.paragraph}>
            If you return your car using our 14-day money-back guarantee, we’ll
            give you a full refund, including the £99 admin fee.
          </p>
        </div>
      </Drawer>
    )}
  </>
)
