import { HoldingImage } from '@cinch-nx/shared-ui/lib/holding-image/holding-image'
import cx from 'classnames'
import Image from 'next/image'

import styles from './product-image.module.css'

interface Props {
  isAvailable?: boolean
  image?: string
  unavailableImageMessage: string
  unavailableVehicleMessage?: string
  alt: string
  isPending: boolean
  children?: React.ReactNode
}

interface UnavailableProps extends Props {
  isAvailable: false
  unavailableVehicleMessage: string
}

export const ProductImage: React.FC<Props | UnavailableProps> = ({
  isAvailable,
  image,
  alt,
  children,
}) => {
  const hasImage = image && image.length > 0

  return (
    <div className={styles.imgContainer}>
      {hasImage ? (
        <Image
          className={cx(styles.img, { [styles.unavailable]: !isAvailable })}
          src={image}
          alt={alt}
          layout="fill"
        />
      ) : (
        <HoldingImage text="Image Unavailable" isAvailable={isAvailable} />
      )}
      {children}
    </div>
  )
}
