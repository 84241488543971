import { Text } from '@cinch-labs/design-system'
import cx from 'classnames'

import skeletonStyles from '../skeleton.module.css'
import styles from './tags.module.css'

export interface TagItem {
  label?: string
  value: string | number
}

interface Props {
  items: TagItem[]
  isPending: boolean
  listStyleClassName?: string
  listItemClassName?: string
  isUpperCaseText?: boolean
}

export const Tags: React.FC<Props> = ({
  items = [],
  isPending = false,
  listStyleClassName,
  listItemClassName,
  isUpperCaseText = true,
}) => (
  <ul
    className={cx(styles.list, listStyleClassName || '', {
      [skeletonStyles.base]: isPending,
      [skeletonStyles.active]: isPending,
      [styles.upperCase]: isUpperCaseText,
    })}
  >
    {items.map(({ label, value }) => (
      <Text
        key={label}
        element="li"
        fontSizeFixed="3xs"
        className={cx(styles.item, listItemClassName || '', {
          [skeletonStyles.base]: isPending,
          [skeletonStyles.active]: isPending,
        })}
        title={label}
      >
        {value}
      </Text>
    ))}
  </ul>
)

export default Tags
