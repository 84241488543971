import styles from './admin-fee-button.module.css'

interface AdminFeeButtonProps {
  onAdminFeeClick?: () => void
}

const AdminFeeButton: React.FC<AdminFeeButtonProps> = ({ onAdminFeeClick }) => (
  <button
    onClick={onAdminFeeClick}
    aria-label="Plus a £99 admin fee. Find out more info"
    className={styles.adminFeeInfoButton}
  >
    Plus a £99 admin fee
  </button>
)

export default AdminFeeButton
