import cx from 'classnames'

import styles from './card.module.css'

interface Props {
  isHoverable?: boolean
  element?: keyof JSX.IntrinsicElements
  children: React.ReactNode
}

export const Card: React.FC<Props> = ({
  isHoverable,
  element = 'div',
  children,
}) => {
  const HtmlElement = element

  return (
    <HtmlElement
      className={cx(styles.card, {
        [styles.hoverable]: isHoverable,
      })}
    >
      {children}
    </HtmlElement>
  )
}
