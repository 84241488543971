import { Text, designTokens } from '@cinch-labs/design-system'
import cx from 'classnames'
import { useState } from 'react'
import AdminFeeButton from '../admin-fee-button/admin-fee-button'
import { AdminFeeDrawer } from '../admin-fee-drawer/admin-fee-drawer'
import { TrackingEventTypes, useAnalytics } from '@cinch-nx/shared-analytics'

import { Card } from '../card/card'
import Price from '../price/price'
import { ProductImage } from '../product-image/product-image'
import { SaveButton } from '../save-button/save-button'
import { Subtitle } from '../subtitle/subtitle'
import { Tags } from '../tags/tags'
import { Title } from '../title/title'
import styles from './product-list-card.module.css'

interface TagItem {
  label: string
  value: string
}

export interface ProductListCardProps {
  id: string
  image?: string
  price: number
  title: string
  subtitle: string
  isAvailable?: boolean
  isSaved: boolean
  useMonthly?: boolean
  isPending: boolean
  tags: TagItem[]
  unavailable?: string
  isUnfavourited?: boolean
  isReserved: boolean
  quoteRegularPaymentInPence?: number | null
  quoteType?: string | null
  make: string
  model: string
  discountInPence?: number
  vehicleYear?: string | number
  showActions?: boolean
  onSave?: (newVal: boolean) => void
  onCardClick?: () => void
  isSmallCardSize?: boolean
  shouldShowAdminFee: boolean
  isPersonalised?: boolean
}

const filterTestTags = (tag: TagItem) => {
  if (tag.label) {
    return tag.label.match(/Mileage|Fuel Type|Transmission Type/)
  }

  return null
}
const hyphenate = (text: string | undefined) =>
  text ? text.replace(/\s+/g, '-').toLowerCase() : ''

const { font } = designTokens.customProperties.ds
const ERROR_MESSAGE = 'Image Unavailable'

export const ProductListCard: React.FC<ProductListCardProps> = ({
  id,
  price,

  image,
  title,
  subtitle,
  isSaved,
  isPending = false,
  isAvailable,
  onSave,
  tags,
  unavailable,
  isReserved,

  make,
  model,
  vehicleYear,
  discountInPence,
  showActions = true,
  isSmallCardSize = false,
  shouldShowAdminFee,
  isPersonalised,
  onCardClick,
}) => {
  const tagsCarCards = tags.filter((tag) => filterTestTags(tag))
  const vehicleTitle = `${vehicleYear} ${title}`
  const showOfferTag = !!discountInPence
  const [shouldShowAdminFeeDrawer, setShouldShowAdminFeeDrawer] =
    useState(false)

  const { trackEvent } = useAnalytics()

  const handleAdminFeeClick = () => {
    setShouldShowAdminFeeDrawer(!shouldShowAdminFeeDrawer)
    if (shouldShowAdminFeeDrawer === false) {
      trackEvent({
        eventName: 'product list card - admin fees',
        type: TrackingEventTypes.ADOBE,
        data: {
          event: {
            name: 'click',
            category: 'admin fees',
            label: 'info',
            action: 'view',
            value: isPersonalised ? 'personalised' : 'non-personalised',
          },
        },
      })
    }
  }
  return (
    <Card data-testid="product-list-card" element="article" isHoverable>
      <div
        className={cx(styles.meta, styles.metaCarCards, {
          [styles.unavailable]: !isAvailable,
        })}
      >
        <div>
          <Title
            element="h2"
            className={cx(styles.title, styles.titleCarCards)}
            fontSizeFixed={'m'}
            type={'cardTitle'}
            isPending={isPending}
            aria-describedby={isReserved ? `vehicle-reserved-${id}` : undefined}
          >
            <a
              data-testid="product-list-card-link"
              href={`/used-cars/${hyphenate(make)}/${hyphenate(
                model,
              )}/details/${id}`}
              className={styles.link}
              onClick={() => (onCardClick ? onCardClick() : null)}
            >
              {vehicleTitle}
            </a>
          </Title>

          <Subtitle isPending={isPending} fontSize={'s'}>
            {subtitle}
          </Subtitle>

          <Tags
            isPending={isPending}
            listStyleClassName={styles.tagsCarCards}
            listItemClassName={styles.tagItemCarCard}
            items={tagsCarCards}
          />
        </div>

        {isAvailable && (
          <Price
            isPending={isPending}
            price={price}
            vehicleId={id}
            shouldShowCarsOnOfferTag={showOfferTag}
            discountInPence={discountInPence}
            showSmallFontSize={isSmallCardSize}
          />
        )}
        {shouldShowAdminFee && (
          <>
            <AdminFeeButton onAdminFeeClick={() => handleAdminFeeClick()} />
            <AdminFeeDrawer
              showAdminFeeDrawer={shouldShowAdminFeeDrawer}
              onClose={() => handleAdminFeeClick()}
            />
          </>
        )}
      </div>

      <div className={styles.imageContainer}>
        <ProductImage
          alt={title}
          isAvailable={isAvailable}
          image={image}
          unavailableImageMessage={
            (!isAvailable && ' ') || unavailable || ERROR_MESSAGE
          }
          isPending={isPending}
        />

        {showActions && (
          <div className={styles.actions}>
            {!!onSave && isAvailable && (
              <SaveButton
                isPending={isPending || (!isAvailable && !isSaved)}
                className={styles.save}
                isSaved={isSaved}
                onClick={() => onSave(!isSaved)}
                size={font.size.xxl}
              />
            )}
          </div>
        )}

        {isReserved && (
          <Text
            fontWeight="book"
            className={styles.reserved}
            data-testid="vehicle-is-reserved"
            id={`vehicle-reserved-${id}`}
          >
            Currently reserved
          </Text>
        )}
      </div>
    </Card>
  )
}
